<template>
    <div class="vap-page">
    <div class="vap-page-header">
      <h1>
        <span v-if="configName" style="color: black;">{{ configName }}</span>
        <span v-else>Untitled Configuration</span>
      </h1>
      <div class="btns-container">
        <VasionButton
          class="last-btn"
          :classProp="'secondary'"
          @vasionButtonClicked="cancel()"
        >
          Cancel
        </VasionButton>
        <VasionButton
          :classProp="'primary'"
          :isDisabled="!isDirty"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="row">
        <VasionInput
          v-model="configName"
          class="row-item"
          :required="true"
          inputType="top-white"
          width="392"
          :title="'CONFIGURATION NAME'"
          @input="markAsDirty"
        />
        <VasionDropList
          v-slot="slotItem"
          v-model="attributeForm"
          class="row-item"
          :required="true"
          :dataArray="attributeFormList"
          width="392"
          :title="`${$formsLabel.toUpperCase()}`"
          placeholder="..."
          type="plain-list"
          displayName="name"
          valueName="value"
          @input="markAsDirty"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <VasionCheckbox
        id="barcode-value-as-filename-checkbox"
        name="barcode-value-as-filename-checkbox"
        class="row-checkbox"
        :checked="barcodeValueAsFilename"
        @change="markAsDirty(); barcodeValueAsFilename = !barcodeValueAsFilename"
      >
        Barcode Value as File Name
      </VasionCheckbox>
      <div class="row">
        <VasionInput
          id="page-number"
          v-model="selectedPageNumber"
          class="number-input"
          title="PAGE NUMBER"
          :isDisabled="!barcodeValueAsFilename"
          :width="'100px'"
          specialType="number"
          inputType="top-white"
          @input="markAsDirty()"
        />
        <VasionInput
          id="barcode-number"
          v-model="selectedBarcodeNumber"
          class="number-input"
          title="BARCODE NUMBER"
          :isDisabled="!barcodeValueAsFilename"
          :width="'100px'"
          specialType="number"
          inputType="top-white"
          @input="markAsDirty()"
        />
      </div>
      <table>
        <tr class="headerRow">
          <th v-for="(headerColumn, column) in dataTable.Columns.Values" :key="column" class="table-header">
            {{ headerColumn }}
          </th>
        </tr>
        <tr v-for="(rowValues, row) in dataTable.Rows.Values" :key="row">
          <td class="table-data" :class="{'grey-background': row % 2 === 0}">
            {{ rowValues.Values[0] }}
          </td><td class="table-data" :class="{'grey-background': row % 2 === 0}">
            <VasionInput
              id="`page-number-${row}`"
              v-model="rowValues.Values[1]"
              :width="'100px'"
              specialType="number"
              class="number-input"
              inputType="top-white"
            />
          </td>
          <td class="table-data" :class="{'grey-background': row % 2 === 0}">
            <VasionInput
              id="`barcode-number-${row}`"
              v-model="rowValues.Values[2]"
              :width="'100px'"
              specialType="number"
              class="number-input"
              inputType="top-white"
            />
          </td>
          <td class="table-data" :class="{'grey-background': row % 2 === 0}">
            <VasionInput
              id="`from-${row}`"
              v-model="rowValues.Values[3]"
              :isDisabled="!rowValues.Values[1] || !rowValues.Values[2]"
              :width="'100px'"
              specialType="number"
              class="number-input"
              inputType="top-white"
            />
          </td>
          <td class="table-data" :class="{'grey-background': row % 2 === 0}">
            <VasionInput
              id="`to-${row}`"
              v-model="rowValues.Values[4]"
              :isDisabled="!rowValues.Values[1] || !rowValues.Values[2]"
              :width="'100px'"
              specialType="number"
              class="number-input"
              inputType="top-white"
            />
          </td>
        </tr>
      </table>
      <div v-if="!attributeForm" id="no-fields">
        <div>
          <VasionFilesLabeledOutline id="vasion-files-icon" />
        </div>
        <div id="empty-header" class="vasion-large-message">
          There are no fields to view
        </div>
        <div id="empty-subheader" class="vasion-page-subheader">
          Please select an object to view and manage fields
        </div>
      </div>

      <VasionGeneralModal
        id="cancel-confirmation-div"
        :confirmButtonText="'YES'"
        :rejectButtonText="'NO'"
        :modalType="'message'"
        :buttonGroup="'center'"
        :message="'Your changes have not been saved, are you sure you want to cancel?'"
        :sync="showDirtyModal"
        @confirmButtonClick="dirtyModalOKClick()"
        @noButtonClick="dirtyModalCancelClick()"
      />

      <VasionSnackbar
        id="results-snack"
        :showSnackbarBool.sync="showSnackbarBool"
        :snackbarImage="snackbarImage"
        :snackbarSubTitle="snackbarSubTitle"
        :snackbarTitle="snackbarTitle"
      />
      <Loading
        id="throbber"
        :active.sync="isLoading"
        :is-full-page="false"
        :color="loaderColor"
        loader="dots"
        :background-color="loaderBackgroundColor"
      />
    </div>
  </div>
</template>

<script>
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import Loading from 'vue-loading-overlay';

export default {
  name: 'EditBarcode',
  components: {
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && to.name !== 'TheLogin') {
      this.routeNext = next
      this.showDirtyModal = true
    } else {
      next()
    }
  },
  data() {
    return {
      attributeForm: '',
      barcodeValueAsFilename: false,
      configName: '',
      dataTable: {
        Columns: { Values: ['Field', 'Page Number', 'Barcode Number', 'From', 'To'] },
        Rows: { Values: [] },
      },
      Id: 0,
      isDirty: false,
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      savedBarcodeData: {},
      selectedBarcodeNumber: 0,
      selectedPageNumber: 0,
      showDirtyModal: false,
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      title: '',
    }
  },
  computed: {
    activeForm() { return this.$store.state.attributeForm.activeIndexForm },
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
  },
  watch: {
    async attributeForm() {
      if (this.attributeForm.value === this.savedBarcodeData?.IndexFormID) {
        this.dataTable.Rows.Values = this.savedBarcodeData.Fields.map(field => {
           return { Values: [field.IndexFieldTitle, field.PageNumber, field.BarcodeNumber, field.From, field.To, field.IndexFieldID] }
        })
      } else {
        this.savedBarcodeData = {}
        await Promise.all([
          this.$store.dispatch('capture/setBarcodeData', {}),
          this.$store.dispatch('attributeForm/getFormDetails', this.attributeForm.value),
        ])
        this.dataTable.Rows.Values = this.activeForm.fields.map(field => {
           return { Values: [field.title, null, null, null, null] }
        })
      }
    },
    savedBarcodeData() {
      if (this.savedBarcodeData.ID) {
        this.Id = this.savedBarcodeData.ID
        this.attributeForm = { name: this.savedBarcodeData.IndexFormName, value: this.savedBarcodeData.IndexFormID }
        this.activeForm.fields = this.savedBarcodeData.Fields
        this.configName = this.savedBarcodeData.ConfigName
        this.barcodeValueAsFilename = this.savedBarcodeData.BarcodeValueAsFileName
        this.selectedBarcodeNumber = this.savedBarcodeData.FileNameBarcodeNumber
        this.selectedPageNumber = this.savedBarcodeData.FileNamePageNumber
      }
    },
  },
  async created() {
    const promises = await Promise.all([
      this.$store.dispatch('attributeForm/getForms'),
      this.$store.state.capture.barcodeData,
    ])
    // eslint-disable-next-line prefer-destructuring
    this.savedBarcodeData = promises[1]

    window.addEventListener('beforeunload', this.browserWarnDirty)
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.browserWarnDirty)
  },
  methods: {
    async save() {
      if (!this.validate()) {
        this.snackbarTitle = 'Error'
        this.snackbarImage = false
        this.showSnackbarBool = true
        return
      }
      const fields = this.dataTable.Rows.Values.map((row, index) => {
        return {
          IndexFieldID: this.savedBarcodeData.ID ? row.Values[5] : this.activeForm.fields[index].fieldID,
          IndexFieldTitle: row.Values[0],
          PageNumber: row.Values[1],
          BarcodeNumber: row.Values[2],
          From: row.Values[3],
          To: row.Values[4],
        }
      })
      const payload = {
        ID: this.Id,
        IndexFormID: this.attributeForm.value,
        IndexFormName: this.attributeForm.name,
        ConfigName: this.configName,
        BarcodeValueAsFileName: this.barcodeValueAsFilename,
        FileNamePageNumber: this.barcodeValueAsFilename ? this.selectedBarcodeNumber : null,
        FileNameBarcodeNumber: this.barcodeValueAsFilename ? this.selectedPageNumber : null,
        Fields: fields,
      }
      this.isLoading = true
      const response = await this.$store.dispatch('admin/saveBarcodeIndex', payload)
      this.isLoading = false
      if (response && !response?.data.ResultMessage) {
        this.isDirty = false
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Configuration Saved'
        this.snackbarImage = true
        this.showSnackbarBool = true
        setTimeout(() => {
          this.$router.push({ name: 'BarcodeConfiguration' })
        }, 1500)
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Configuration Not Saved'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }
    },
    cancel() { this.$router.push({ name: 'BarcodeConfiguration' }) },
    dirtyModalCancelClick() { this.showDirtyModal = false },
    dirtyModalOKClick() { this.routeNext() },
    markAsDirty() { this.isDirty = true },
    markAsClean() { this.isDirty = false },
    validate() {
      this.snackbarSubTitle = ''

      let errors = false
      if (this.configName === '') {
        this.snackbarSubTitle += 'Missing Configuration Name.\n'
        errors = true
      }
      if (this.configName.trim() === '') {
        this.snackbarSubTitle += 'Configuration Name cannot only include spaces.\n'
        errors = true
      }
      if (this.attributeForm === '') {
        this.snackbarSubTitle += `Missing ${$formsLabel}.\n`
        errors = true
      }
      if (errors) return false

      let completedAtLeastOneRow = false
      let containsValuesLessThanOne = false
      let fromLessThanTo = false
      this.dataTable.Rows.Values.forEach(row => {
        if (Boolean(row.Values[1]) && Boolean(row.Values[2])) {
          completedAtLeastOneRow = true
        }
        if ((row.Values[1] <= 0 && Boolean(row.Values[1]))
        || (row.Values[2] <= 0 && Boolean(row.Values[2]))
        || (row.Values[3] <= 0 && Boolean(row.Values[3]))
        || (row.Values[4] <= 0 && Boolean(row.Values[4]))) {
          containsValuesLessThanOne = true
        }
        if (Boolean(row.Values[3]) && Boolean(row.Values[4])) {
          if (row.Values[3] >= row.Values[4]) {
            fromLessThanTo = true
          }
        }
      })
      if (this.barcodeValueAsFilename && this.selectedPageNumber && this.selectedBarcodeNumber) {
        completedAtLeastOneRow = true
      }

      if (!completedAtLeastOneRow) {
        this.snackbarSubTitle += 'At least one row must be completed.\n'
      }
      if (containsValuesLessThanOne || (this.barcodeValueAsFilename && (this.selectedBarcodeNumber <= 0 || this.selectedPageNumber <= 0))) {
        containsValuesLessThanOne = true
        this.snackbarSubTitle += 'All values must be greater than or equal to 1.\n'
      }
      if (fromLessThanTo) {
        this.snackbarSubTitle += '"From" values must be less than "To" values.\n'
      }
      return completedAtLeastOneRow && !containsValuesLessThanOne && !fromLessThanTo
    },
    browserWarnDirty(event) {
      if (this.isDirty) {
        event.preventDefault()
        event.returnValue = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .row {
    display: flex;
    flex-direction: row;
    margin: 0px;
  }

  .row-item {
    padding-right: 10px;
  }

  .row-checkbox {
    padding-top: 29px;
    padding-bottom: 15px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #no-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
  }

  #empty-header {
    font-size: 20px;
    color: black;
    margin-bottom: 10px;
  }

  #empty-subheader {
    font-size: 18px;
  }

  #vasion-files-icon{
    fill: red;
  }

  .headerRow {
    background-color: $table-header-bg;
    color: $table-header-color;
  }

  table {
    margin-top: 30px;
    width: 100%;
  }

  .table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: $table-header-bg;
    color: $table-header-color;
    padding: 15px 0 15px 15px;
    white-space: nowrap;
  }
  .table-header label {
    font-weight: normal !important;
  }
  .table-data {
    padding: 10px 0 10px 15px;
  }
  .number-input {
    padding-right: 10px;
  }

  .grey-background {
    background-color: $grey-50;
  }

</style>
